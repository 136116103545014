const setPrototypeOf = (function(Object, magic) {
  'use strict';
  var set;
  function checkArgs(O, proto) {
    // React calls Object.setPrototypeOf with function type, exit
    if (typeof O === 'function') { return; }
    if (typeof O !== 'object' || O === null) {
      throw new TypeError('can not set prototype on a non-object');
    }
  }
  function setPrototypeOf(O, proto) {
    checkArgs(O, proto);
    set.call(O, proto);
    return O;
  }
  try {
    // this works already in Firefox and Safari
    set = Object.getOwnPropertyDescriptor(Object.prototype, magic).set;
    set.call({}, null);
  } catch (o_O) {
    set = function(proto) {
      this[magic] = proto;
    };
    setPrototypeOf.polyfill = setPrototypeOf(
      setPrototypeOf({}, null),
      Object.prototype
    ) instanceof Object;
  }
  return setPrototypeOf;
}(Object, '__proto__'))

export { setPrototypeOf }
